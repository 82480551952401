// Colori
$header-blu: #0b5ba6;
$menu-black: #041c33;
$blu-icon: #041C33;
$blu-menu: #0F355A;
$blu-title: #0C1134;
$purple: #b3032f;
$button-gray: #c7c9cb;
$gray-text: #666666;
$gray-divider: #ebebeb;
$gray-bg: #f0f2f3;
$gray-search: #bdbdbd;
$icon-background-gray: #e6e6e6;
$arrows-gray: #cdd2d6;
$blu-footer: #0B5BA6;
$black-meta:#444c53;
$footer-color: #85add3;


// Fonts
@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(http://fonts.googleapis.com/css?family=PT+Serif:400,400i);
@import url(http://fonts.googleapis.com/css?family=PT+Sans:400,700);

$perpetua: 'PerpetuaTitlingMT-Light' !important;
$ptsans : 'PT Sans';
$ptsource : 'Source Sans Pro'; 
$ptserif : 'PT Serif';

//Other settings
.grid-container{
	@media screen and (max-width: 1439px) {
		max-width: 1100px;
	}
}

$body-font-family: $ptsans, sans-serif;

body{
	font-family: $body-font-family;
	background:$gray-bg;
	border-top: 5px solid $menu-black;
	h1, h2, h3, h4, h5, h6, p, a, span{
		font-family: $body-font-family;
		color: $black-meta;
	}
}

// Some general classes
// where sif is the main class of body for this project
.sif {
	.main-content{
		padding-bottom:120px;
	}
	.fa{
		font-size:15px;
	}
	p{
		margin-bottom:10px;
	}
	.carousel-arrows{
		.fa{
			font-size:55px;

		}
	}
	.bold{
		font-weight:bold;
	}
	.breadcrumbs{
		margin-bottom:0;
		li{
			text-transform:none;
			font-size:15px;
			line-height:45px;
			color: gray;
			a{
				color: black;
			}
		}
	}
	.purple{
		color: $purple;
	}
	.full-width{
		margin:0;
		padding:0;
		width:100%;
		max-width:100%;
	}

	.rotate-45{
		@include rotate(45deg);
	}
	hr{
	 	&.full-width{
			border-color: $gray-divider;
			margin-bottom:30px;
		}
	}
	a{
		@include transition(opacity .3s ease);
		&:hover{
			@include opacity(0.8);
		}
	}
}


// Header 

header{
	&.show-for-large{
		background: $header-blu url(../img/header-bg.png) no-repeat scroll 90% bottom / 54% auto;
		.main-header-container{
			padding-top: 15px;
			padding-bottom: 15px;
			.main-logo{
				margin-top:12px;
				margin-bottom:12px;
				display:block;
			}
		}
	}
	&.hide-for-large{
		.main-header-container{
			border-bottom: 1px solid $gray-divider;
			.main-logo{
				display:block;
				float:left;
				margin-left:10px;
				margin-right:10px;
				margin-top:7px;
			}
			.top-bar-mobile{
				float:right;
				&::after{
					clear:both;
				}
				.social-links{
					float:left;
					margin-top:23px;
					margin-right:20px;
					li{
						line-height:27px;
						margin-left:12px;
						text-align:center;
						width:27px;
						@include border-radius(27px);
						a{
							color:$blu-footer;
						}

					}
					/* Small only */
					@media screen and (max-width: 39.9375em) {
						display:none;

					}
				}
				.title-bar{
					width:75px;
					height:75px;
					background: $header-blu;
					float:left;
					.menu-icon{
						margin-left:20px;
						margin-right:20px;
						&::after{
							top:-3px;
						}
					}
				}
				.search-form{
					float:left;
				    margin-right:1px;
					.search-form-input{
						position:relative;
						input{
							z-index:2;
						}
						a{
							width:75px;
							height:75px;
							background: $gray-search;

							border:0;
							line-height:75px;

							padding:0;
							text-align: center;
							@include transition(all .5s ease);
							.fa{
								color:white;
								font-size:25px;
							}
						}
					}
				}
			}
			#search-input-mobile{
				display:none;
				.search-form-input{
					padding:9px;
					.input-group-field{
						border-color:$gray-divider !important;

					}
				}

			}
		}
	}
	.main-header-container{
		margin-bottom:10px;
		.social-links{
			list-style:none;
			margin:0;
			padding:0;
			li{
				background:white;
				display:inline-block;
				line-height:33px;
				margin-left:10px;
				text-align:center;
				width:33px;
				@include border-radius(30px);
				a{	
					display:inline-block;
					text-align:center;
					font-family: $ptserif;
					font-size: 15px;
				}
			}
		}
		.mobile-site-title{
			font-family: $ptserif;
			font-weight:bold;
		    font-size: 18px;
		    line-height: 1.0;
			margin-bottom:0;
			margin-top: 17px;
		}
	}
}


// Navigation 
navigation{
	padding-top:10px;
	padding-bottom:10px;
	.main-navigation-container{
		@include breakpoint(medium only) {
			padding-top:0px;
			padding-bottom:0px;
		}
		.top-bar{
			background:none;
			padding:0;
			ul{
				background:none;
				li {
					a{
						line-height:45px;
						padding:0.7rem 1.4rem;
					}
					&:first-child{
						a{
							padding-left:0;
							@media screen and (max-width: 768px) {
								padding:0.7rem 1.4rem;
							}
						}
						ul{
							li {
								a{
									padding-left:1.5rem;
								}
							
							}
						}
					}
					ul{
						background:white;
					}
				}
			}
			.search-form{
				.search-form-input{
					position:relative;
					&:after{
					    content: "\f002"; 
						font-family: FontAwesome;
						font-style: normal;
						font-weight: normal;
						text-decoration: inherit;
						position: absolute;
						font-size: 24px;
						color: $blu-icon;
						top: 0;
						right: 10px;
						margin: 0;
						z-index: 1;
					}
					input{
						border:none;
						position:relative;
						background:none;
						z-index:2;
						box-shadow: none;
						@include transition(background .3s ease);
						&:focus{
							background: rgb(255, 255, 255); /* Fall-back for browsers that don't support rgba */
	    					background: rgba(255, 255, 255, .5);
						}
					}
				}
			}
		}
		.menu{
			li {
				a{
					text-transform:uppercase;
					color: $menu-black;
					font-family: $ptsans;
					color:$blu-menu;
					font-size:17px;
				}
			}
		}
	}
}




// Orbit slider
.orbit{
	.orbit-wrapper{
		.orbit-container{
			.orbit-slide{
				.orbit-caption{
					/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,041c33+100&0+0,1+100 */
					background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(4,28,51,1) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(4,28,51,1) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(4,28,51,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#041c33',GradientType=0 ); /* IE6-9 */
					.orbit-caption-title{
						font-family: $ptsans;
						font-weight: bold;
						font-size: 36px;
						line-height:1.1;
						color:white;
						margin-bottom: 20px;
					}
					p{
						font-family:$ptserif;
						font-size:16px;
						line-height:24px;
						color:white;
					}
				}
			}
		}
	}
}


// Box Container
.box-container{
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.22);
	margin-bottom:28px;
	background:white;
	position:relative;
	.box-title{
		font-size: 48px;
		margin-top:20px;
		line-height:50px;
	}

	.box-content{
		position:relative;
		padding-top:20px;
		padding-left:15px;
		padding-right:15px;
		padding-bottom:15px;
		.category-label{
			position:absolute;
			background: $purple;
		    color: white;
		    font-size: 12px;
		    font-weight: bold;
		    left: 15px;
		    line-height: 20px;
		    padding-left: 8px;
		    padding-right: 8px;
		    position: absolute;
		    text-transform: uppercase;
		    top: -15px;
		}
		.box-content-paragraph{
			font-style: $ptsans;
			font-size:12px;
			line-height:14px;
			font-style:italic;

		}
		.box-content-title{
			font-style: $ptsans;
			font-size:18px;
			line-height:24px;
			margin-bottom:10px;
		}
		&.text-content{
			padding:20px 15px;
		}
		.box-share{
			border-bottom:1px solid $gray-divider;
			margin-bottom:20px;
			padding-right:35px;
			line-height:45px;
			.fa{
				color:$purple;
				margin-right:5px;
				font-size:15px;
				margin-left:20px;

			}
			a{
				font-weight:normal;
				text-transform: uppercase;
			}

		}
		.box-title{
			margin-bottom:25px;
			margin-top:35px;
		}
		.box-subtitle{
			line-height:45px;
			font-family: "PT Sans", sans-serif;
    		color: #444c53;
    		padding-left:35px;
    		padding-right:35px;
    		font-size:17px;
    		text-transform:uppercase;
    		font-weight:bold;
    		border-bottom: 1px solid $gray-divider;
    		border-top: 1px solid $gray-divider;
    		margin-left:-35px;
    		margin-right:-35px;
    		margin-top:15px;
    		margin-bottom:15px;
		}

	}

	.box-header{
		font-size:17px;
		text-transform: uppercase;
		font-weight:bold;
		padding-left:15px;
		padding-right:15px;
		line-height:45px;
		position:relative;
		border-bottom: 1px solid $gray-divider;
		.box-meta{
			margin-bottom:10px;
			.single-meta{
				margin-right:10px;
				font-size:15px;
				color:$black-meta;
				.fa{
					color: $gray-text;
				}
			}
		}
		.category-label{
			text-transform:uppercase;
			color:$purple;
		}
		&.icon-text{	
			min-height:30px;
			padding-top:3px;
			span{
				.fa{
					color:$purple;
					font-size:16px;
					margin-right:5px;
				}
				line-height:2px;
				text-transform: uppercase;
				font-size:14px;
			}
		}
		img{
			width:100%;
		}
	}

	.box-footer{
		border-top: 1px solid $button-gray;
		padding-left:12px;
		position:relative;
		text-transform: uppercase;
		line-height:45px;
		.orbit-bullets{
			line-height:0;
		    padding-bottom: 5px;
		    padding-top: 7px;
			margin:0;
			text-align:left;
			button{
				height:12px;
				width:12px;
				background-color:$button-gray;
				&.is-active{
					background-color:$purple;
				}
			}
		}

		&.icon-top-right{
			min-height:30px;
			.fa{
				position:absolute;
				background: $icon-background-gray;
				@include border-radius(30px);
			    height: 30px;
			    width: 30px;
			    font-size: 16px;
			    text-align: center;
			    line-height: 2;
			    right:15px;
			    top: -15px;
			}
		}

		&.icon-link{	
			span{
				font-size:17px;
				line-height:45px;
				text-transform: uppercase;
				font-weight:700;
				.fa{
					color:$purple;
					margin-right:5px;
				}
			}
		}
	}

	&.purple-box{	
		background:$purple;
		.box-header{
			background:white;
		}
		.box-content{
			color:white;
			padding:25px 15px;
			min-height:208px;
			ul {
				list-style-type: square;
				color: red;
				margin-bottom:0;
				li{
					color:white;
					font-family: $ptserif;
					line-height: 21px;
					font-size:15px;
    				padding-bottom: 25px;
				}
			}
		}
	}

	&.header-img{
		.box-header{
			background-size:cover;
			min-height:123px;
			padding:0;
		}
	}

	&.header-icon-text{
		.box-header{
			span{
				.fa{
					color:$purple;
					font-size:15px;
					margin-right:5px;
				}
				text-transform: uppercase;
			}
		}
	}

	&.footer-icon-bottom-right{
		.box-footer{
			min-height:30px;
			.fa{
				position:absolute;
				background: $icon-background-gray;
				@include border-radius(30px);
			    height: 30px;
			    width: 30px;
			    font-size: 16px;
			    text-align: center;
			    line-height: 2;
			    right:15px;
			    top: -15px;
			    color: $purple;
			}
		}
	}

	&.no-padding-content{
		.box-content{
				padding:0;
		}
	}

	&.video-content{
		.box-content{
			background-position:center center;
			img{
				width:100%;
			}
		}
		.box-header{
			.fa{
				position:absolute;
				background: $icon-background-gray;
				z-index:2;
				@include border-radius(30px);
			    height: 30px;
			    width: 30px;
			    text-align: center;
			    line-height: 2;
			    left: 15px;
			    top: 15px;
			    color: $purple;
			}
		}
	}

	&.no-shadow{
		-webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
	}

	&.magazine-preview{
		border-bottom:1px solid $gray-divider;
		padding-bottom:40px;
		margin-top: 30px;
		.box-content{
			padding-left:15px;
		}
	}

	&.magazine-preview:last-of-type{
		border-bottom:none;
	}

	&.page-content{
		.box-header{
			padding-top:20px;
			text-transform: none;
			padding-left:35px;
			padding-right:35px;
			.single-category{
				font-size:15px;
				color: $purple;
				text-transform: uppercase;
			}
		}
		.box-content{
			padding-bottom:50px;
			.main-page-content{
				padding-left:35px;
				padding-right:35px;
			}
			p{
				font-size:18px;
				font-family: $ptsource;
				line-height:24px;
			}
			small{
				font-size:15px;
				font-style:italic;
				text-align:center;
			}

			ul{
				list-style-type:none;
				margin-left:0;
				li{
					font-size: 18px;
				    font-family: "Source Sans Pro";
				    line-height: 28px;
				    padding-left:40px;
				    position:relative;
					&::before{
						    content: "\f00c"; 
							font-family: FontAwesome;
							font-style: normal;
							font-weight: normal;
							text-decoration: inherit;
							position: absolute;
							font-size: 24px;
							color: $black-meta;
							top: 0px;
							left: 0px;
							margin: 0;
							z-index: 1;
					}
				}
			}
		}
	}

	&.page-content-simple{
		.box-header{

		}
	}

	&.bold-header-title{
		.box-header{
			span{
				font-weight:bold;
				color:$black-meta;
			}
		}
	}

	&.purple-footer-text{
		.box-footer{
			span{
				color: $purple;
			}
		}
	}

	&.home-banner{
		.box-content{
			img{
				width:100%;
			}
		}
		
	}

	&.absolute-footer{
		.box-footer{
			position:absolute;
			width:100%;
			bottom:0;
			background:white;
		}
	}
	&.footer-icon-link{	
		.box-footer{
			span{
				font-size:17px;
				line-height:45px;
				text-transform: uppercase;
				font-weight:700;
				.fa{
					color:$purple;
					margin-right:5px;
				}
			}
		}

	}
	&.header-small-title{
		.box-header{
			padding-top:0;
			border-bottom:0;

			.header-subtitle{
				line-height:45px;
				font-family: "PT Sans", sans-serif;
	    		color: #444c53;
	    		padding-left:35px;
	    		padding-right:35px;
	    		font-size:17px;
	    		text-transform:uppercase;
	    		font-weight:bold;
	    		border-bottom: 1px solid $gray-divider;
	    		border-top: 1px solid $gray-divider;
	    		margin-left:-35px;
	    		margin-right:-35px;
	    		margin-top:15px;
	    		margin-bottom:15px;
			}
		}
	}
}

// Carousel Riviste
.carousel-container{
	.box-content{
		text-align:center;
		.magazine-carousel{
			margin:5px auto 10px;
			width:85%;
			.owl-stage{
				padding-bottom:10px;
				.owl-item{
					@include shadow;
				}
			}

		}
		.carousel-arrows{
			z-index:1;
			.owl-prev{
				position:absolute;
				left:3%;
				color:$arrows-gray;
				top:38%;
			}
			.owl-next{
				position:absolute;
				right:3%;
				color:$arrows-gray;
				top:38%;
			}
		}
	}	
}

// Box Single News
.box-single-news-preview{
	text-align:left;
	margin-bottom:30px;
	img{
		margin-top:5px;
		width:100%;
	}
	.single-news-content-preview{
		padding-left:10px;
		padding-right: 10px;

		.single-news-title{
			color: $blu-title;
			font-size:20px;
			line-height:21px;
			font-weight:bold;
		}

		.single-news-content{
			color: $gray-text;
			font-size:15px;
			font-family: $ptserif;
			line-height: 21px;
			margin-bottom:5px;
		}

		.single-news-date{
			text-transform: uppercase;
			font-size:14px;
			font-size:$ptsans;
		}
	}
}

// Footer
footer{
	background: $blu-footer;
	padding-top:115px;
	padding-bottom:120px;
	color:white;
	p{
		color:white;
	}
	hr{
		width:80%;
		margin-left:0;
		@include opacity(0.3);
		margin-bottom:20px;
	}
	a{
		color:white;
		&:hover{
		 color:white;
		}
	}
	.footer-title{
		margin-top:20px;
		font-family:$ptserif;
		margin-bottom:30px;
		color:white;
	}
	.address{
		@include opacity(0.5);
	}
	.footer-links{
		.links-title{
			font-family:$ptserif;
			text-transform: uppercase;
			font-weight:bold;
			color:white;
			margin-bottom:20px;
		}

		ul{
			list-style-type:none;
			margin-left:0;
			margin-bottom:40px;
			li{
				a{
					@include opacity(0.5);
				}
			}
		}
	}
}

// Listing Riviste
.magazine-preview{
	font-size:18px;
	.magazine-title{
		color: $purple;
		font-weight:bold;
		font-size:22px;
		line-height:1.2;
		margin-bottom:0;
	}

	.magazine-data{
		padding-bottom:20px;
		p{
			margin:0px;
			font-size:16px;
		}
	}

	.magazine-links{
		a{
			color: $purple;
			font-size:16px;
		}
	}

	ul{
		list-style-type:none;
		margin-left:0;
		li{
			border-bottom: 1px solid $gray-divider;
			a{	
				font-size:15px;
				color: black;
				line-height:45px;
				margin-left:5px;
				text-transform: uppercase;
			}
		}
	}
}

// Pagination
.pagination{
	padding-top:10px;
	padding-bottom:10px;

	li{
		a{
			&:hover{	
			@include border-radius(28px);
			}
		}
	}

	.current{
		background:$purple !important;
		@include border-radius(28px);
	}
}

// Sidebar
.sidebar{
	.accordion{
		.accordion-title{
			text-transform: uppercase;
			color: black;
			font-family: $ptsans;
			font-size:15px;
			line-height:45px;
			padding-top:0;
			padding-bottom:0;
			padding-left:30px;
			&::before{
				    content: "\f105"; 
					font-family: FontAwesome;
					font-style: normal;
					font-weight: normal;
					text-decoration: inherit;
					position: absolute;
					font-size: 24px;
					color: $purple;
					line-height:45px;
					top: 0px;
					left: 13px;
					margin: 0;
					z-index: 1;
			}
			&[aria-selected="false"]{
				&::before{
					    content: "\f107"; 
						font-family: FontAwesome;
						font-style: normal;
						font-weight: normal;
						text-decoration: inherit;
						position: absolute;
						font-size: 24px;
						color: $purple;
						line-height:45px;
						top: 0px;
						left: 10px;
						margin: 0;
						z-index: 1;
				}
			}
			span{
				display:block;
				line-height:45px;
			}
		}

		.accordion-content{
			padding:15px;
			ul{
				list-style-type:none;
				margin-left:15px;
				padding-bottom:15px;
				li{
					a{
						color:$purple;
						font-size:15px;
						line-height:30px;
					}
				}
			}
		}

		.accordion-item-link{
			a{
				text-transform: uppercase;
				color: black;
				font-family: $ptsans;
				font-size:15px;
				padding: 25px 30px;
			    border: 1px solid #e6e6e6;
			    border-bottom: 0;
			    line-height:1px;
			    display:block;
			}
		}
	}

	.box-single-news-preview{
		border-bottom:1px solid $gray-divider;
		padding-bottom:30px;
		margin-bottom:0px;
		margin-top:20px;
		.single-news-content-preview{
			padding-left:15px;
			padding-right:15px;
		}
	}
}

// Social Share
.social-share{
	list-style-type:none;
	display:table;
	margin-right:0;
	margin-left:auto;
	li{
		background:$purple;
		height:45px;
		width:45px;
		text-align:center;
		margin-bottom:30px;
		padding:0 !important; 
		@include border-radius(46px);
		&::before{
			content: " " !important; 
		}
		a{
			color:white;
			.fa{
				font-size:20px;
				line-height:45px;
			}
		}
	}
}